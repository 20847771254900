@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: #ced4da;
}
p {
  font-size: 1.15rem;
  line-height: 1.7;
}
.full-height {
  height: calc(100vh - 80px) !important;
}
.navbar-custom {
  background-color: rgb(12, 12, 12) !important;
  padding: 0 !important;
}
.background {
  background-image: url("./images/background.jpg");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  filter: grayscale(10%);
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.btn-primary {
  background-color: #662d91 !important;
  border-color: #662d91 !important;
  color: #ced4da;
}
.pink {
  color: #662d91 !important;
}
.nav-menu {
  display: flex;
  align-items: center;
}
.activeNavItem {
  color: #662d91 !important;
  font-size: 1.3rem !important;
}
.nav-link {
  display: flex !important;
  align-items: center !important;
  text-decoration: none !important;
  padding: 0 1rem !important;
  justify-content: center !important;
  height: 80px !important;
  letter-spacing: 1px !important;
  cursor: pointer !important;
  font-size: 1.15rem !important;
}
.nav-link:hover {
  color: #662d91 !important;
}
.navbar-brand {
  padding: 0 !important;
}
.footer {
  background-color: rgb(12, 12, 12) !important;
  color: #ced4da;
}
.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.icon {
  font-size: 2rem;

  margin: 1.25rem;
  color: #ced4da !important;
}
.icon:hover {
  color: #662d91 !important;
}
.smallfs {
  font-size: 14px !important;
}
